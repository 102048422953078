<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="setFilter">
          <div class="row">
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.documentIds" id="filter_id" :label="$t('article.document_id')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.site"
                :options="sites"
                :label="$t('article.filter.site')"
                id="filter_site"
                disable-form-group
              >
              </app-select>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.title" id="filter_title" :label="$t('article.filter.title')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <label>{{ $t('article.filter.categories') }}</label>
              <app-multi-select
                v-model="filter.categories"
                :options="categories"
                label="title"
                track-by="id"
                id="filter_categories"
              >
              </app-multi-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.dateFrom"
                id="filter_orderDateFrom"
                :label="$t('tasrNews.filter.created_at_from')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.dateTo"
                id="filter_orderDateTo"
                :label="$t('tasrNews.filter.created_at_to')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('buttons.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import TopicOfferFilter from '../../model/TopicOfferFilter'
import Select from '../form/select/Select'
import Datepicker from '../form/Datepicker'
import MultiSelect from '../form/select/MultiSelect'

export default {
  name: 'TopicOfferFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(TopicOfferFilter)
    }
  },
  computed: {
    categories () {
      return this.$store.getters['category/all']
    },
    sites () {
      return this.$store.getters['site/all'].filter(site => site.enabled)
    }
  },
  components: {
    appInput: Input,
    appSelect: Select,
    appDatepicker: Datepicker,
    appMultiSelect: MultiSelect
  },
  methods: {
    setFilter () {
      this.$store.commit('article/setPage', 1)
      this.$store.commit('article/setFilter', this.filter)
      this.$store.dispatch('article/fetch', 'withoutElastic')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(TopicOfferFilter)
      this.$store.commit('article/setFilter', this.filter)
      this.$store.dispatch('article/fetch', 'withoutElastic')
    }
  },
  beforeMount () {
    this.filter = this._.cloneDeep(TopicOfferFilter)
    this.$store.commit('article/setFilter', this.filter)
  }
}
</script>
