<template>
  <app-module-view>
    <template slot="body">
      <app-article-create-modal
        v-if="transferArticle"
        :article-to-take-over="transferArticle"
        titleMessage="topicOffer.transfer_article"
        @close="toggleTransferModal"
      >
      </app-article-create-modal>
      <app-topicOffer-filter></app-topicOffer-filter>
      <div class="top-buttons">
        <div class="d-inline-block">
          <app-checkbox
            id="don_npa_autorefresh"
            v-model="autoRefresh"
            :label="$t('auto_refresh')">
          </app-checkbox>
        </div>
      </div>
      <div v-if="autoRefresh">
        <app-autoloader :callback="fetchList" :height="2" :interval="120"></app-autoloader>
      </div>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="tasrNews"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            :showEventButtons="isTransferButtonVisible"
            @page-change="setPageAndGetRecords"
            @toggleTransferModal="toggleTransferModal"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import { mapState } from 'vuex'
import ModuleView from '../ModuleView'
import Preloader from '../preloader/Preloader'
import TopicOfferFilter from './TopicOfferFilter'
import DataTable from '../shared/DataTable'
import Autoloader from '../Autoloader'
import Checkbox from '../form/Checkbox'
import ArticleCreateModal from '../article/ArticleCreateModal'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'TopicOfferList',
  data () {
    return {
      dataLoaded: false,
      autoRefresh: false,
      dataTableConfig: {
        fields: {
          id: this.$t('article.id'),
          site: this.$t('article.list.site'),
          'field.title': this.$t('article.list.title'),
          categories: this.$t('article.categories'),
          status: this.$t('article.list.status'),
          transferredArticles: this.$t('modal.published_count'),
          orderDate: this.$t('article.list.order_date')
        },
        actions: {
          custom: [
            {
              route_name: 'topicOffer_detail',
              route_param: 'id',
              route_source_param: 'id',
              title: this.$t('topicOffer.show'),
              text: this.$t('topicOffer.show')
            }
          ],
          event: [
            {
              event: 'toggleTransferModal',
              title: this.$t('topicOffer.transfer'),
              text: this.$t('topicOffer.transfer')
            }
          ]
        },
        render: {
          site: (id) => this.getSiteTitle(id),
          categories: (ids) => ids?.map(
            (id) => '<span class="text-nowrap">' + this.getCategoryTitleById(id) + '</span>'
          ).join(', '),
          status: (nonExistingField, article) => {
            const hasSomeTransferred = Boolean(article.transferredArticles?.length)
            const color = hasSomeTransferred ? 'btn-green' : 'label-inverse'
            const label = this.$t(`topicOffer.status.${hasSomeTransferred ? 'transferred' : 'new'}`)
            return `<span class="label ${color} text-uppercase"><strong>${label}</strong></span>`
          },
          transferredArticles: (ids) => ids?.length
        }
      },
      transferArticle: null
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isTransferButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.ARTICLE_OFFER_TOPIC_OFFER_PERMISSIONS.transferArticleButton
      return this.$store.getters['user/hasPermission'](permission)
    },
    sites () {
      return this.$store.getters['site/all']
    },
    categories () {
      return this.$store.getters['site/all']
    },
    tasrNews () {
      return this.$store.getters['article/list']
    },
    totalCount () {
      return this.$store.getters['article/totalCount']
    },
    page () {
      return this.$store.getters['article/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appTopicOfferFilter: TopicOfferFilter,
    appDataTable: DataTable,
    appAutoloader: Autoloader,
    appCheckbox: Checkbox,
    appArticleCreateModal: ArticleCreateModal
  },
  methods: {
    toggleTransferModal (article = null) {
      this.transferArticle = article
    },
    fetchList () {
      this.$store.dispatch('article/fetch', 'withoutElastic')
        .then(() => {
          this.dataLoaded = true
        })
    },
    getArticleByDocumentId (documentId) {
      let articleObj = null
      this.articles.forEach(article => {
        if (article.documentId === documentId) {
          articleObj = article
        }
      })
      return articleObj
    },
    setPageAndGetRecords (page) {
      this.$store.commit('article/setPage', page)
      this.fetchList()
    },
    getSiteTitle (id) {
      const site = this.sites.find((site) => site.id === id)
      if (site === undefined) return id
      return site.title
    },
    getCategoryTitleById (id) {
      const category = this.$store.getters['category/categoryById'](id)
      if (category) return category.title
      return id
    }
  },
  mounted () {
    this.setPageAndGetRecords(1)
  },
  beforeMount () {
    this.$store.commit('article/setView', 'expanded')
  }
}
</script>

<style lang="scss">
  .table-responsive {
    td:last-child {
      white-space: nowrap;
    }
    td:last-child a {
      margin-right: 2px;
    }
  }
  .card-no-border .card {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .top-buttons {
    position: absolute;
    top: -56px;
    right: 8px;
    z-index: 5;
  }
</style>
